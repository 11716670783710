import { useTranslation } from 'react-i18next';

import SearchIcon from './../icons/Search';

import { Input, type InputProps } from '@/components/ui/input';

const GamesSearchInput = (props: InputProps) => {
	const { t } = useTranslation();

	return (
		<div className="flex w-full flex-row items-center gap-x-2 rounded-lg border bg-primary px-3">
			<SearchIcon />
			<Input
				className="w-full border-none bg-transparent py-2.5 text-sm font-medium"
				tabIndex={-1}
				type="text"
				name="search"
				placeholder={t('global_search_placeholder')}
				{...props}
			/>
		</div>
	);
};

export default GamesSearchInput;
