import { useUnit } from 'effector-react';
import React from 'react';

import { $filteredGames, updateSearchTerm, Game } from '@whale-client/dataflow-games';
import useLayoutContext from '../hooks/useLayoutContext';

import GameCard from '@/lib/components/GameCard';
import GameCategory from '@/lib/components/GameCategory';
import GamesSearchInput from '@/lib/components/GamesSearchInput';

const SearchInput = () => {
	const { navigateToGame } = useLayoutContext();
	const [filteredGames, onSearchTermChange] = useUnit([$filteredGames, updateSearchTerm]);
	const waitMillisecondsForNextInput = 300;
	const [debounceTimeout, setDebounceTimeout] = React.useState<NodeJS.Timeout | null>(null);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newSearchTerm = event.target.value;

		if (debounceTimeout) {
			clearTimeout(debounceTimeout);
		}

		const newTimeout = setTimeout(() => {
			onSearchTermChange(newSearchTerm);
		}, waitMillisecondsForNextInput);

		setDebounceTimeout(newTimeout);
	};

	const onGameCardClicked = (game: Game) => {
		navigateToGame(game.provider_slug, game.slug);
	};

	return (
		<>
			<GamesSearchInput onChange={handleChange} />
			<GameCategory>
				{filteredGames?.length > 0 && <GameCategory.Title>Search Result</GameCategory.Title>}
				<GameCategory.Cards>
					{filteredGames.map((game) => (
						<GameCard key={game.game_code} onClick={() => onGameCardClicked(game)}>
							<GameCard.Banner>
								<img className="cursor-pointer rounded-xl" src={game.url_thumb} />
							</GameCard.Banner>
							<GameCard.Title>{game.name}</GameCard.Title>
							<GameCard.Provider>{game.provider}</GameCard.Provider>
						</GameCard>
					))}
				</GameCategory.Cards>
			</GameCategory>
		</>
	);
};

export default SearchInput;
