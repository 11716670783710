import * as React from 'react';

import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';

type GameCategoryProps = {
	children: React.ReactNode;
};

type GameCategoryTitleProps = {
	children: React.ReactNode;
	icon?: React.ReactNode;
};

interface GameCategoryNamespace {
	InlineCardsWrapper: React.FC<GameCategoryProps>;
	PaginationDefault: React.FC;
	Title: React.FC<GameCategoryTitleProps>;
	Cards: React.FC<GameCategoryProps>;
}

const GameCategory: React.FC<GameCategoryProps> & GameCategoryNamespace = ({ children, ...props }) => {
	return (
		<div className="flex flex-col justify-center gap-y-3" {...props}>
			{children}
		</div>
	);
};

GameCategory.PaginationDefault = () => {
	return (
		<>
			<CarouselNext />
			<CarouselPrevious />
		</>
	);
};

GameCategory.Title = ({ children, icon }: GameCategoryTitleProps) => {
	return (
		<div className="flex flex-row items-center">
			{icon}
			<h2 className="flex flex-row items-center gap-2 px-4 text-lg font-semibold text-white">{children}</h2>
		</div>
	);
};

GameCategory.InlineCardsWrapper = ({ children }: GameCategoryProps) => {
	return <div className="flex flex-row gap-x-3.5 overflow-auto">{children}</div>;
};

GameCategory.Cards = ({ children }: GameCategoryProps) => {
	const gameCards = React.Children.toArray(children);

	return (
		<Carousel
			opts={{
				align: 'start',
				loop: true,
			}}
		>
			<CarouselContent>
				{gameCards.map((child, index) => (
					<CarouselItem key={index} className="max-w-[150px]">
						{child}
					</CarouselItem>
				))}
			</CarouselContent>
		</Carousel>
	);
};

export default GameCategory;
